.region-desktop {
  position: relative;
  margin-bottom: 0.75rem; }

.region {
  position: relative;
  margin-bottom: 0 !important; }

.GIB-container {
  position: relative; }

.GIB-imageBanner {
  width: 100%;
  height: auto; }

.GIB-container picture {
  display: block; }

.GIB-container a,
a:hover,
a:focus {
  text-decoration: none;
  cursor: pointer;
  outline: none; }

/* Content ALWAYS hover Image Block */
.GBIB-content-container,
.GIB-content-container {
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

/* END Content Horizontal Alignment PD Attribute */
/* Content Vertical Alignment PD Attribute */
.GBIB-alignment-vertical-arriba {
  top: 35%; }

.GBIB-alignment-vertical-centro {
  top: 65%; }

.GBIB-alignment-vertical-abajo {
  top: 85%; }

.GIB-alignment-vertical-arriba {
  top: 27%; }

.GIB-alignment-vertical-centro {
  top: 50%; }

.GIB-alignment-vertical-abajo {
  top: 73%; }

/* END Content Horizontal Alignment PD Attribute */
#faqs-page .GIB-text p {
  font-size: 32px;
  margin-bottom: 0;
  font-weight: bold;
  line-height: 40px; }
  @media (min-width: 769px) {
    #faqs-page .GIB-text p {
      font-size: 34px; } }
  @media (min-width: 992px) {
    #faqs-page .GIB-text p {
      font-size: 45px;
      line-height: 60px; } }

.GIB-text h2 {
  font-size: 32px; }
  @media (min-width: 769px) {
    .GIB-text h2 {
      font-size: 34px; } }
  @media (min-width: 992px) {
    .GIB-text h2 {
      font-size: 45px; } }

.GIB-text h3 {
  font-size: 6vw; }
  @media screen and (min-width: 768px) {
    .GIB-text h3 {
      font-size: 2.5vw; } }

/* Opacity PD Attribute */
.GIB-opacity {
  -webkit-filter: brightness(60%);
          filter: brightness(60%); }

/*
.GIB-opacity {
    position: relative;
}

.GIB-opacity::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.4);
}
*/
/* END Opacity PD Attribute */
/* Button PD Attribute */
.GIB-button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%; }

.GIB-button {
  text-transform: uppercase;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 8px 20px;
  letter-spacing: 0.5px;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  width: 125px; }
  .GIB-button.GIB-buttonStyle-fino {
    height: 25px; }
  .GIB-button.GIB-buttonStyle-ancho {
    height: 35px; }
  .GIB-button:only-child:not(.single) {
    min-width: 35%; }
  .GIB-button.single {
    width: 200px; }

.GIB-button2 {
  margin-left: 10%; }

/* END Button PD Attribute */
/* Zoom PD Attribute */
.GIB-image-hover {
  -webkit-transition: 1.15s;
  transition: 1.15s; }

.GIB-image-hover:hover {
  -webkit-transform: scale(1.15);
          transform: scale(1.15); }

.GIB-image-hover-container {
  overflow: hidden;
  display: block; }

/* END Zoom PD Attribute */
/* Content Horizontal Alignment PD Attribute */
.GBIB-alignment-centro,
.GBIB-alignment-izquierda,
.GBIB-alignment-derecha {
  text-align: center;
  width: 100%;
  right: 0; }

.GBIB-alignment-centro,
.GBIB-alignment-izquierda {
  left: 0; }

.GIB-alignment-centro,
.GIB-alignment-izquierda,
.GIB-alignment-derecha {
  text-align: center;
  width: 100%;
  right: 0; }

.GIB-alignment-centro,
.GIB-alignment-izquierda {
  left: 0; }

/* END Content Horizontal Alignment PD Attribute */
/* Image hover content text */
.GIB-contentImageTop {
  width: 90%;
  display: block;
  margin: auto; }
  @media (max-width: 768px) {
    .GIB-contentImageTop {
      position: absolute;
      margin: auto;
      top: 15%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      left: 0;
      right: 0;
      width: 50%; } }

/* END hover under content text */
/* Image under content text */
.GIB-contentImageBottom {
  width: 40%;
  display: block;
  margin: auto; }

/* END Image under content text */
/* Content under Image Block only in mobile Format */
@media (min-width: 768px) {
  .GBIB-content-container-mobileBottom,
  .GIB-content-container-mobileBottom {
    position: absolute;
    margin: auto;
    line-height: 140%;
    font-size: 16px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); } }

@media (max-width: 768px) {
  .GBIB-content-container-mobileBottom h1,
  .GBIB-content-container-mobileBottom h2,
  .GBIB-content-container-mobileBottom h3,
  .GBIB-content-container-mobileBottom p,
  .GIB-content-container-mobileBottom h1,
  .GIB-content-container-mobileBottom h2,
  .GIB-content-container-mobileBottom h3,
  .GIB-content-container-mobileBottom p {
    color: black; } }

/* Content ALWAYS under Image Block */
.GBIB-content-container-fixedBottom,
.GIB-content-container-fixedBottom {
  display: block;
  text-align: left; }
  .GBIB-content-container-fixedBottom h1,
  .GBIB-content-container-fixedBottom h2,
  .GBIB-content-container-fixedBottom h3,
  .GBIB-content-container-fixedBottom p,
  .GIB-content-container-fixedBottom h1,
  .GIB-content-container-fixedBottom h2,
  .GIB-content-container-fixedBottom h3,
  .GIB-content-container-fixedBottom p {
    color: black;
    text-align: left; }

/* Background Content PD Attribute */
.GIB-background-blanco-intenso {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 4%; }

.GIB-background-blanco-medio {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 4%; }

/* END Background Content PD Attribute */
/* Espacios entre componentes PageDesigner */
@media (min-width: 768px) {
  .GIB-button {
    width: 200px; }
  .GBIB-alignment-derecha {
    text-align: left;
    right: 0;
    width: 40%;
    padding-right: 60px; }
  .GBIB-alignment-izquierda {
    text-align: justify;
    width: 45%;
    padding-left: 60px; }
  .GIB-button2 {
    margin-left: 50px; } }
